/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useCallback } from "react";
import { postRequest } from "../Contexts/Services";
import { useNotification } from "../Contexts/NotificationContext";
import { useSpinner } from "../Contexts/SpinnerContext";

function useTransactions() {
    const [transactions, setTransactions] = useState([]);
    const { showNotification } = useNotification();
    const { showSpinner, hideSpinner } = useSpinner();

    /**
     * Charge la liste des transactions depuis l'API.
     * @function
     * @async
     * @param {function} showSpinner - Fonction pour afficher le spinner de chargement.
     * @param {function} hideSpinner - Fonction pour cacher le spinner de chargement.
     * @param {function} showNotification - Fonction pour afficher une notification.
     * @param {function} postRequest - Fonction pour envoyer une requête POST à l'API.
     * @param {Object} setTransactions - Fonction pour mettre à jour la liste des transactions.
     */
    const loadListTransaction = useCallback(async () => {
        showSpinner();
        try {
            //console.log("start load transaction")
            const response = await postRequest('api.php', {
                uuid: localStorage.getItem('loggedIn'),
                action: 'load_transaction'
            });
        
            if (response.success === true) {
             //console.log(response.message);
             //   setTransactions(response.message);
                setTransactions({ ...response.message });
                hideSpinner();
            } else {
                showNotification(response.message || 'Une erreur est survenue.', "error", "", 0);
                hideSpinner();
            }

        } catch (error) {
        showNotification(error.message || "Une erreur est survenue.", "error", "", 0);
        hideSpinner();
        }
    }, [ ]);

  return { transactions, loadListTransaction };
}

export default useTransactions;
