import { useState } from "react";
import { postRequest } from "../../../Contexts/Services"; // Importez la fonction postRequest

import { useNotification } from "../../../Contexts/NotificationContext"; // Importez le hook useNotification

import { useSpinner } from "../../../Contexts/SpinnerContext";

/**
 * Composant pour afficher un formulaire de connexion.
 * @returns {JSX.Element} Élément JSX du formulaire de connexion.
 */
function LoginForm() {
  //const { login } = useAuth();
  const { showNotification } = useNotification(); // Utilisez le hook pour obtenir la fonction showNotification
  const { showSpinner, hideSpinner } = useSpinner();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  /**
   * Gère la soumission du formulaire.
   * @param {Event} e - Événement de soumission du formulaire.
   */
  const handleSubmit = async (e) => {
    e.preventDefault();
    /* afficher spinner */
    showSpinner();

    try {
      await login(email, password);
    } catch (err) {
      showNotification(err.message, "error", "", 0); // Utilisez showNotification pour afficher l'erreur
      /* cacher spinner */
      hideSpinner();
    }
  };

  /**
   * Connecte l'utilisateur.
   * @param {string} email - Email de l'utilisateur.
   * @param {string} password - Mot de passe de l'utilisateur.
   */
  async function login(email, password) {
    let isComplete = true;
    let message_erreur = "Erreur: \n";

    if (!email) {
      message_erreur += "- Email est requis. \n";
      isComplete = false;
    }

    if (!password) {
      message_erreur += "- Mot de passe est requis. \n";
      isComplete = false;
    }

    if (isComplete === false) {
      showNotification(message_erreur, "error", "", 0);
      /* cacher spinner */
      hideSpinner();
      return;
    }

    try {

      email = email.trim();
      password = password.trim();
      
      const data = await postRequest("api.php", {
        email,
        password,
        action: "connect",
      });
      console.log(data);
      if (data.success) {
        localStorage.setItem("loggedIn", data.message.uuid);
        localStorage.setItem("email", data.message.email);
        localStorage.setItem("free", data.message.free);
        localStorage.setItem("matic_wallet", data.message.matic_wallet);
        setTimeout(() => {
          hideSpinner();
          showNotification("Vous êtes connecté", "success", "/", 3);
        }, 4000);
        
      } else {
        showNotification(data.message, "error", "", 0);
        hideSpinner();
      }
    } catch (err) {
      showNotification(err.message, "error", "", 0);
      hideSpinner();
    } finally {
      /* cacher spinner */
      
    }
  }

  return (
    <div className="centered-content">
      <div className="login-form">
        <h2>Connexion</h2>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input
              className="form-control inputLogin"
              id="email"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label htmlFor="password">Mot de passe</label>
            <input
              className="form-control inputLogin"
              id="password"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <button className="btn btn-primary bp_encodage"  type="submit">
            Se connecter
          </button>

          <div
            className="lien_objet_auth"
            onClick={() => (window.location.href = "/reset-password")}
          >
            Mot de passe oublié?
          </div>

          <div
            className="lien_objet_auth"
            onClick={() => (window.location.href = "/register")}
          >
            Inscription
          </div>
        </form>
      </div>
    </div>
  );
}

export default LoginForm;
