import React, { useState } from 'react';
import { postRequest } from '../../../Contexts/Services';
import { useNotification } from "../../../Contexts/NotificationContext"; // Importez le hook useNotification
import { Link } from 'react-router-dom';
import { useSpinner } from "../../../Contexts/SpinnerContext";
const UpgradeToFullVersion = () => {
    const [userMaticAddress, setUserMaticAddress] = useState('');
    
    const { showNotification } = useNotification(); // Utilisez le hook pour obtenir la fonction showNotification
	const { showSpinner, hideSpinner } = useSpinner();
  


    const handleUpgrade = async () => {
        // Vous pouvez envoyer l'adresse Matic de l'utilisateur au backend pour vérification
        //const response = await postRequest('/path/to/your/upgrade/endpoint', { maticAddress: userMaticAddress });
        // Gérez la réponse ici

        const uuid = localStorage.getItem('loggedIn');
        if (!uuid) {
            showNotification("'Erreur: UUID non trouvé.", "error", "", 0); 
            return;
        }

        const userEmail = localStorage.getItem('email');
        if (!userEmail) {
            showNotification("'Erreur: Email non trouvé.", "error", "", 0); 
            return;
        }

        if (!userMaticAddress) {
            showNotification("Votre adresse Matic est manquant", "error", "", 0);
            /* cacher spinner */
            hideSpinner();
            return;
        }
        showSpinner();
        try {
           
            const response = await postRequest('api.php', { 
                uuid: localStorage.getItem('loggedIn'),
                action: 'add_matic_address',
                maticAddress : userMaticAddress,
                userEmail : userEmail
            });
    
            if (response.success) {
                showNotification(response.message || "wallet enregistré pour validé le paiement", "success", "/", 3);
                hideSpinner();
            } else {
                showNotification(response.message , "error", "", 0); 
                hideSpinner();
            }
            
        } catch (error) {
            showNotification(error.message || "Erreur lors du backup.", "error", "", 0); 
            hideSpinner();
        }
    };



    return (
        <div className='container_upgrade'>
            <h2>Passez à la version complète pour gérer un nombre illimité de cryptos</h2>
            <p>Enregistrez votre adresse Matic dans votre compte wallet-crypto pour la validation du paiement </p>
            <p>Payez 10 Matic à l'adresse suivante pour passer à la version complète :</p>
            <p>Adresse Matic pour le paiement: 0x456F51f4642CDA357D9D37D844c8680989a90c89</p>
           
            <input 
            className="form-control inputupgrade"
                type="text"
                value={userMaticAddress}
                onChange={e => setUserMaticAddress(e.target.value)}
                placeholder="votre adresse Matic pour vérification"
            />
            <button className="btn btn-primary bp_encodage_upgrade"  onClick={handleUpgrade}>Upgrade</button>
            <br />
            {/* Bouton pour retourner à HomeComponent */}

            <br />
            <Link to="/" className="return-home-btn">Retourner à l'accueil</Link>
        </div>
    );
};

export default UpgradeToFullVersion;
