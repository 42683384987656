/* eslint-disable react-hooks/exhaustive-deps */
import React, {  useState , useEffect , useCallback} from "react";
import {  FaTrashAlt } from 'react-icons/fa';

import { useNotification } from "../../../Contexts/NotificationContext"; // Importez le hook useNotification

import { useSpinner } from "../../../Contexts/SpinnerContext";
import { postRequest } from "../../../Contexts/Services"; // Importez la fonction postRequest

import TokenGraph from "../Utils_ui/TokenGraph";


/**
 * Composant pour afficher le tableau général des cryptomonnaies.
 * @param {Object} props - Les propriétés du composant.
 * @param {Object} props.dataTransaction - Les données de transaction pour les cryptomonnaies.
 * @param {Function} props.loadListTransaction - La fonction pour charger la liste des transactions.
 * @returns {JSX.Element} - Le code JSX du composant.
 */
function TableauGeneralCryptos({ dataTransaction, loadListTransaction }) {

    const { showNotification } = useNotification();
    const [verifiedDataTableauGeneral, setVerifiedDataTableauGeneral] = useState([]);
    const [showDetailsModal, setShowDetailsModal] = useState(false);
    const [selectedTokenDetails, setSelectedTokenDetails] = useState(null);
    
    const { showSpinner, hideSpinner } = useSpinner();

   /* useEffect(() => {
            const result = verifDataTransactionCrypto();
            setVerifiedDataTableauGeneral(result);
     }, []);
*/


     useEffect(() => {
        const result = verifDataTransactionCrypto();
        setVerifiedDataTableauGeneral(result);
        if (selectedTokenDetails) {
            const updatedTokenDetails = verifiedDataTableauGeneral.cryptos.find(crypto => crypto.tokenId === selectedTokenDetails.tokenId);
            setSelectedTokenDetails(updatedTokenDetails);
        }

    }, [dataTransaction, verifiedDataTableauGeneral, selectedTokenDetails]);

    /**
     * Vérifie les données de transaction pour les cryptomonnaies.
     * @returns {Object|Array} - Les données de transaction vérifiées ou un tableau vide.
     */
    const verifDataTransactionCrypto = () => {
        //console.log(dataTransaction);
        if (dataTransaction && dataTransaction.cryptos) {
           return dataTransaction;
        } else {
            return [];
        }

    };


    
    

    /**
     * Affiche les détails d'une transaction pour un token donné.
     * @param {string} tokenId - L'identifiant du token pour lequel afficher les détails.
     * @returns {void}
     */
    const showDetailTransactionToken = useCallback((tokenId) => {
        const tokenDetails = verifiedDataTableauGeneral.cryptos.find(crypto => crypto.tokenId === tokenId);
        if (tokenDetails) {
            setSelectedTokenDetails(tokenDetails);
            setShowDetailsModal(true);
        } else {
            showNotification(`Détails non trouvés pour le token ${tokenId}`, "error", "", 0);
        }
    }, [verifiedDataTableauGeneral, showNotification]);


    /**
     * Ferme la fenêtre modale des détails de transaction.
     * @returns {void}
     */
    const handleCloseDetails = useCallback(() => {
        setShowDetailsModal(false);
    }, []);
    

   
    /**
     * Gère la suppression d'une transaction en envoyant une requête POST à l'API.
     * @param {number} id - L'identifiant de la transaction à supprimer.
     * @returns {Promise<void>} - Une promesse qui ne renvoie rien.
     */
    const handleDeleteTransaction = useCallback(async (id) => {
        const confirmation = window.confirm("Êtes-vous sûr de vouloir supprimer cette transaction?");
         
            if (confirmation) {
                showSpinner();
                try {
                    const response = await postRequest('api.php', {
                        uuid: localStorage.getItem('loggedIn'),
                        action: 'delete_transaction',
                        transaction_id: id
                    });
                
                    if (response.success === true) {
                        //.log(response.message);
                        showNotification(response.message, "success", "", 0);
                        loadListTransaction();
                        hideSpinner();
                    } else {
                        showNotification(response.message || 'Une erreur est survenue.', "error", "", 0);
                        hideSpinner();
                    }
        
                } catch (error) {
                showNotification(error.message || "Une erreur est survenue.", "error", "", 0);
                hideSpinner();
                }
            }
        })

        
        
    return (
        <div className="tableau_general_cryptos">
            <h2>Tableau Général</h2>

            {/* Tableau résumé */}
            <table id="crypto-table-resume" aria-label="Tableau résumé" >
                <thead>
                    <tr>
                        <th><span className="abbrev-title">Tot. Inv.</span></th>
                        <th><span className="abbrev-title">Tot. P&L $</span></th>
                        <th><span className="abbrev-title">Tot. P&L %</span></th>
                        <th><span className="abbrev-title">Val. $</span></th>
                    </tr>
                </thead>
                <tbody>
                    {verifiedDataTableauGeneral && verifiedDataTableauGeneral.totalInvestAllCrypto !== undefined &&  (
                        <tr style={{ color: verifiedDataTableauGeneral["total_p&l_dollar"] >= 0 ? 'green' : 'red' }}>
                            <td>{verifiedDataTableauGeneral.totalInvestAllCrypto.toFixed(2) || 0 }</td>
                            <td>{verifiedDataTableauGeneral["total_p&l_dollar"].toFixed(2) || 0 }$</td>
                            <td>{verifiedDataTableauGeneral["total_p&l_percent"].toFixed(2) || 0}%</td>
                            <td>{verifiedDataTableauGeneral["totalValueAllCrypto"].toFixed(2)  || 0 }$</td>
                        </tr>
                    )}
                    
                </tbody>
            </table>


            <table id="crypto-table" aria-label="Tableau des cryptomonnaies" >
                <thead className="tr_title">
                    <tr >
                        <th className="th_crypto_table"  data-sort="token">Token</th>
                        <th>Logo</th>
                        <th className="th_crypto_table"  data-sort="supply">Supply</th>
                        <th className="th_crypto_table"  data-sort="invest"><span className="abbrev-title">Inv.</span></th>
                        <th className="th_crypto_table"  data-sort="costPrice"><span className="abbrev-title">Prix~ $</span></th>
                        <th className="th_crypto_table"  data-sort="currentPrice"><span className="abbrev-title">Prix Jour</span></th>
                        <th className="th_crypto_table"  data-sort="plDollar"><span className="abbrev-title">P&L $</span></th>
                        <th className="th_crypto_table"  data-sort="plPercent"><span className="abbrev-title">P&L %</span></th>
                        <th className="th_crypto_table" >Action</th>
                    </tr>
                </thead>
                <tbody>
                {  verifiedDataTableauGeneral && verifiedDataTableauGeneral.cryptos && verifiedDataTableauGeneral.cryptos.map(crypto => (
                            <tr key={crypto.tokenId} style={{ color: crypto["total_p&l_usd"] >= 0 ? 'green' : 'red' }} className="tr_hover" >
                                <td className="td_crypto_table" >{crypto.tokenId.toUpperCase()}</td>
                                <td className="td_crypto_table" ><img className="logo_tableau" src={crypto.image} alt={crypto.tokenId} /></td>
                                <td className="td_crypto_table" >{crypto.totalSupply.toFixed(4)  || 0 }</td>
                                <td className="td_crypto_table" >{crypto.totalInvest.toFixed(4)  || 0 }</td>
                                <td className="td_crypto_table" >{crypto.averagePrice.toFixed(6)  || 0 }</td>
                                <td className="td_crypto_table" >{crypto.lastPrice.toFixed(6)  || 0 }</td>
                                <td className="td_crypto_table" >{crypto["total_p&l_usd"].toFixed(2)  || 0 } </td>
                                <td className="td_crypto_table" >{crypto["total_p&l_pourcent"].toFixed(2)  || 0 }%</td>
                                <td className="td_crypto_table" ><button className="bp_encodage" onClick={() => showDetailTransactionToken(crypto.tokenId)}>Détails</button></td>
                            </tr>
                        ))}
                </tbody>
            </table>

            
            {showDetailsModal  && (
                        <div className="modal-overlay">
                            <div className={`modal-content-detail`}>        
                                {selectedTokenDetails  && (
                                    <><div className="crypto-details">
                                <div className="crypto-header">
                                    <div className="crypto-title">
                                        <h3>{selectedTokenDetails.tokenName} ({selectedTokenDetails.tokenId})</h3>
                                    </div>
                                    <div className="crypto-logo-container">
                                        <img src={selectedTokenDetails.image} alt={selectedTokenDetails.tokenName} className="crypto-logo" />
                                    </div>
                                    <div className="crypto-ath-close">
                                        <div className="close-modal">
                                            <button className="bp_femer_modal_detail" onClick={handleCloseDetails}>Fermer</button>
                                        </div>
                                        <div className="crypto-ath">
                                            <p>All Time High (ATH): {selectedTokenDetails.ath} USD</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="table-scroll-container">
                                    <h3  className='underTitre'>Récapitulatif pour {selectedTokenDetails.tokenId}</h3>
                                    <table id="selectCryptoDataResume" className="table-scroll">
                                        <thead>
                                            <tr>
                                                <th>Total Investi</th>
                                                <th>Total Supply</th>
                                                <th>Total P&L en $</th>
                                                <th>Total P&L en %</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr 
                                                className={
                                                    selectedTokenDetails['total_p&l_usd'] > 0 ? 'positive' :
                                                    selectedTokenDetails['total_p&l_usd'] < 0 ? 'negative' :
                                                    ''
                                                }
                                            >
                                                <td>{selectedTokenDetails.totalInvest}</td>
                                                <td>{selectedTokenDetails.totalSupply}</td>
                                                <td>{selectedTokenDetails['total_p&l_usd']}</td>
                                                <td>{selectedTokenDetails['total_p&l_pourcent']}%</td>
                                            </tr>
                                        </tbody>
                                    </table> 
                                    <h3 className='underTitre'>Détails pour {selectedTokenDetails.tokenId}</h3>
                                    {/* Tableau des transactions */}
                                    <table  id="selectCryptoData" className="table-scroll">
                                        <thead>
                                            <tr>
                                                <th>Date</th>
                                                <th>Investissement</th>
                                                <th>Supply</th>
                                                <th>Prix d'achat</th>
                                                <th>Type</th>
                                                <th>Location</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {selectedTokenDetails.transaction.map((transaction, index) => (
                                                <tr 
                                                key={index} 
                                                className={transaction.transactionType === "achat" ? "transaction-achat" : transaction.transactionType === "vente" ? "transaction-vente" : ""}
                                            >
                                                    <td>{transaction.date}</td>
                                                    <td>{transaction.invest} USD</td>
                                                    <td>{transaction.supply}</td>
                                                    <td>{transaction.purchasePrice}</td>
                                                    <td>{transaction.transactionType}</td>
                                                    <td>{transaction.location_crypto}</td>
                                                    <td>
                                                        <button className="delete-button" onClick={() => handleDeleteTransaction(transaction.id)}>
                                                            <FaTrashAlt />
                                                        </button>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>

                            </div></>
                                    

                                )}
                                
                                {selectedTokenDetails  && 
                                    
                                    <TokenGraph	
                                        data={selectedTokenDetails} 
                                    />
                                }
                            </div>

                            {/* Graphique du portefeuille total */}
                            

                        </div>
            )} 

            
        </div>

    );   
}

/**
 * Composant de tableau pour afficher les cryptomonnaies générales.
 * @module Components/ui/Utils_ui/TableauGeneralCryptos
 * @default export
 */
export default TableauGeneralCryptos;