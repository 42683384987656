import React from "react";


/**
 * Composant Spinner qui affiche un spinner avec un texte.
 * @returns {JSX.Element} Le composant Spinner.
 */
function Spinner() {
  return (
    <div className="spinner-container">
      <div className="spinner"></div>
      <div className="spinner-text">
        "Le bitcoin fera aux banques ce que les e-mails ont fait au secteur
        postal"
      </div>
    </div>
  );
}

export default Spinner;
