// Import des librairies
import React, { useState, useEffect } from "react";
import { FaBars } from "react-icons/fa";

import Sidebar from "./Utils_ui/Sidebar";
import CryptoEncodage from "./Utils_ui/CryptoEncodage";
import useCryptos from "../../Hooks/useCryptos";
import useTransactions from "../../Hooks/useTransactions";

import TableauGeneralCryptos from "./Utils_ui/TableauGeneralCryptos";

import PortfolioGraph from "./Utils_ui/PortfolioGraph";



/**
 * Composant représentant la page d'accueil de l'application.
 * @returns {JSX.Element} Élément JSX représentant la page d'accueil.
 */
function HomeComponent() {
	const [isSidebarVisible, setSidebarVisible] = useState(false);
	const [abonnement /*, setAbonnement*/] = useState(
		localStorage.getItem("free") === "true"
			? "Gratuit Max 5 cryptos différente"
			: "Cryptos Illimité"
	);

    const { cryptos, loadListCryptos } = useCryptos();
    const { transactions, loadListTransaction } = useTransactions();

	const toggleSidebar = () => {
		setSidebarVisible(!isSidebarVisible);
	};


   
    

    

    useEffect(() => {
        loadListCryptos();
        loadListTransaction();
        
        
      }, [loadListCryptos, loadListTransaction]);

	//  let width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
	//  let height = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
	  
	//  console.log(`Width: ${width}px, Height: ${height}px`);

	return (
		<div className="container centered-content">
			<FaBars
				className="menu-icon"
				onClick={toggleSidebar}
				size={24}
				style={{ display: isSidebarVisible ? "none" : "block" }}
			/>

			<Sidebar
				isSidebarVisible={isSidebarVisible}
				toggleSidebar={toggleSidebar}
				userEmail={localStorage.getItem("email")}
				userUuid={localStorage.getItem("loggedIn")}
				refreshData={loadListTransaction}
				refreshCrypto={loadListCryptos}
				userAbo={abonnement}
			/>

			{/* Partie Encodage */}
            <CryptoEncodage 
                reloadTransactions={loadListTransaction} 
                cryptos={cryptos} 
                loadListCryptos={loadListCryptos}
            />

			{/* Tableau Genral Crypto */}
			{transactions && transactions.cryptos && 
				<TableauGeneralCryptos 
					dataTransaction={transactions} 
					loadListTransaction={loadListTransaction}
				/>
			}
            
			{/* Graphique du portefeuille total */}
            {transactions && transactions.cryptos && 
				<PortfolioGraph	
					data={transactions} 
					loadListTransaction={loadListTransaction}
				/>
			}

			<div className="footer">
                <p>© 2023 Gestionnaire de Portefeuille Crypto. Tous droits réservés.</p>
                <p>Pour nous soutenir :</p>
                <ul>
                    <li>Wallet Polygon (Réseaux Polygon) : 0x5c2eabcb75b6138bdfe39fd9a0dae0716c6426bc</li>
                    <li>Wallet Eth (ERC20) : 0x5c2eabcb75b6138bdfe39fd9a0dae0716c6426bc</li>
                </ul>
            </div>
		</div>
	);
}

export default HomeComponent;
