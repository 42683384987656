import React, { createContext, useState, useContext } from 'react';

const SpinnerContext = createContext();

/**
 * Fournit un contexte pour afficher ou masquer un spinner.
 * @param {Object} props - Les propriétés du composant.
 * @param {React.ReactNode} props.children - Les éléments enfants à afficher.
 * @returns {JSX.Element} - Le composant SpinnerProvider.
 */
export function SpinnerProvider({ children }) {
    const [isVisible, setIsVisible] = useState(false);

    const showSpinner = () => setIsVisible(true);
    const hideSpinner = () => setIsVisible(false);

    return (
        <SpinnerContext.Provider value={{ isVisible, showSpinner, hideSpinner }}>
            {children}
        </SpinnerContext.Provider>
    );
}

/**
 * Hook personnalisé qui permet de récupérer le contexte du spinner.
 * @returns {Object} Le contexte du spinner.
 * @throws {Error} Si le hook est utilisé en dehors du SpinnerProvider.
 */
export function useSpinner() {
    
    const context = useContext(SpinnerContext);
    if (context === undefined) {
        throw new Error('useSpinner must be used within a SpinnerProvider');
    }
    return context;
}
