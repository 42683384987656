import React, { createContext, useState, useContext, useEffect } from 'react';
import { postRequest } from './Services';

const AuthContext = createContext();

/**
 * Fournit l'état d'authentification de l'application.
 * @param {Object} props - Les propriétés du composant.
 * @param {React.ReactNode} props.children - Les composants enfants à rendre.
 * @returns {JSX.Element} Élément JSX contenant le fournisseur de contexte d'authentification.
 */
export const AuthProvider = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const uuid = localStorage.getItem('loggedIn');
        const email = localStorage.getItem('email');

        if (uuid && email) {
            postRequest('api.php', { uuid, email, action: 'isauth' })
                .then(data => {
                    setIsAuthenticated(data.success);
                    setIsLoading(false);
                });
        } else {
            setIsLoading(false);
        }
    }, []);

    return (
        <AuthContext.Provider value={{ isAuthenticated, isLoading }}>
            {children}
        </AuthContext.Provider>
    );
};

/**
 * Hook personnalisé pour utiliser le contexte d'authentification.
 * @returns {Object} Objet contenant l'état d'authentification et l'état de chargement.
 * @throws {Error} Lance une erreur si le hook est utilisé en dehors du fournisseur de contexte d'authentification.
 */
export const useAuth = () => {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error('useAuth doit être utilisé à l\'intérieur d\'un AuthProvider');
    }
    return context;
};
