/**
 * Composant d'en-tête de l'application de gestion de portefeuille crypto.
 * @returns {JSX.Element} Composant d'en-tête avec un titre et une description.
 */
import React from 'react';

function Header() {
    return (
        <div className="centered-content header">
            <h1>Bienvenue sur le Gestionnaire de Portefeuille Crypto</h1>
            <p>Suivez vos investissements en cryptomonnaies en temps réel et gérez vos transactions facilement.</p>
        </div>
    );
}

export default Header;


