/* eslint-disable react-hooks/exhaustive-deps */

import { useState, useCallback } from "react";
import { postRequest } from "../Contexts/Services";
import { useNotification } from "../Contexts/NotificationContext";
import { useSpinner } from "../Contexts/SpinnerContext";

/**
 * Hook personnalisé pour récupérer la liste des cryptomonnaies.
 * @returns {Object} Un objet contenant la liste des cryptomonnaies et une fonction pour la charger.
 */
function useCryptos() {
    const [cryptos, setCryptos] = useState([]);
    const { showNotification } = useNotification();
    const { showSpinner, hideSpinner } = useSpinner();

    /**
     * Fonction pour charger la liste des cryptomonnaies.
     */
    const loadListCryptos = useCallback(async () => {
        showSpinner();
        try {
            const response = await postRequest('api.php', {
                uuid: localStorage.getItem('loggedIn'),
                action: 'load_cryptos'
            });
          
            if (response.success === true) {
                hideSpinner();
                setCryptos(response.message);
            } else {
                hideSpinner();
                showNotification(response.message || 'Une erreur est survenue.', "error", "", 0);
            }

        } catch (error) {
            hideSpinner();
            showNotification(error.message || "Une erreur est survenue.", "error", "", 0);
        }
    }, []);

    return { cryptos, loadListCryptos };
}

export default useCryptos;
