import React, { useState, useEffect } from "react";
import {
    VictoryChart,
    VictoryTheme,
    VictoryLine,
    VictoryAxis,
    VictoryLegend,
    VictoryZoomContainer,
    VictoryTooltip,
    VictoryScatter
} from 'victory';

/**
 * Composant graphique pour afficher le graphique du portefeuille total.
 * @param {Object} props - Les propriétés du composant.
 * @param {Object} props.data - Les données du graphique.
 * @returns {JSX.Element} - Le composant graphique du portefeuille total.
 */
function PortfolioGraph({ data }) {
    const [transformedData, setTransformedData] = useState([]);
    const [fontSize, setFontSize] = useState(14);
    const [tooltipFontSize, setTooltipFontSize] = useState(12);
    const [scatterSize, setScatterSize] = useState(3); // Taille par défaut des pastilles

    /**
     * Transforme les données du graphique pour les rendre compatibles avec VictoryChart.
     */
    useEffect(() => {
        if (data && data.generalChartData) {
            const chartData = Object.entries(data.generalChartData).map(([date, values]) => ({
                date: new Date(date),
                totalInvested: values.totalInvested,
                totalDollarValue: values.totalDollarValue,
                infosTransactions: values.infosTransactions,
            }));
            const sortedData = chartData.sort((a, b) => a.date - b.date);
            setTransformedData(sortedData);
        }
    }, [data]);

    /**
     * Met à jour les tailles des éléments du graphique en fonction de la taille de l'écran.
     */
    useEffect(() => {
        const updateSizes = () => {
            const width = window.innerWidth;

            if (width <= 480) {
                setFontSize(10);
                setTooltipFontSize(8);
                setScatterSize(2); // Réduire la taille des pastilles pour les petits écrans
            } else if (width <= 768) {
                setFontSize(8);
                setTooltipFontSize(10);
                setScatterSize(3);
            } else {
                setFontSize(4);
                setTooltipFontSize(4);
                setScatterSize(2); // Augmenter la taille des pastilles pour les grands écrans
            }
        };

        window.addEventListener("resize", updateSizes);
        updateSizes();

        return () => {
            window.removeEventListener("resize", updateSizes);
        };
    }, []);

    /**
     * Renvoie une chaîne de caractères contenant les informations sur les transactions.
     * @param {Array} transactions - Les transactions à afficher.
     * @returns {string} - Les informations sur les transactions.
     */
    const renderTransactions = (transactions) => {
    
        return transactions.map(tr => {
            let symbol = tr.type === 'achat' ? '🟢' : '🔴';
            return `${symbol} Jeton: ${tr.token}
                Investi: ${tr.tokenInvest}$
                Prix d'achat: ${tr.purchasePrice}$
                Location: ${tr.location_crypto}$
                Quantité: ${tr.tokenSupply}`;
        }).join('\n\u200B\n\u200B\n');
    };

    /**
     * Détermine la couleur des pastilles en fonction des transactions.
     * @param {Object} datum - Les données du graphique.
     * @returns {string} - La couleur des pastilles.
     */
    const determineColor = (datum) => {
        if (datum.datum.infosTransactions && datum.datum.infosTransactions.some(tr => tr.type === 'vente')) {
             return "red";
        } else {
            return "grey"; // couleur par défaut si aucun critère n'est rempli
        }
    };

    // Rendu du composant
    return (
        <div id="total-portfolio-chart-container" className='container-graphique' aria-label="Conteneur du graphique du portefeuille total">
            <h2>Graphique du portefeuille total</h2>
            {transformedData.length > 0 && (
                <VictoryChart
                    theme={VictoryTheme.material}
                    height={200}
                    padding={{ top: 20, bottom: 50, right: 30, left: 60 }}
                    domainPadding={20}
                    containerComponent={<VictoryZoomContainer />}>
                    <VictoryAxis
                        tickFormat={(x) => {
                            const date = new Date(x);
                            return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
                        }}
                        style={{ tickLabels: { fontSize } }}
                    />
                    <VictoryAxis
                        dependentAxis
                        tickFormat={(y) => `$${y}`}
                        style={{ tickLabels: { fontSize } }}
                    />
                    <VictoryLine
                        data={transformedData}
                        x="date"
                        y="totalInvested"
                        style={{
                            data: { stroke: "#f54242" }
                        }}
                    />
                    <VictoryLine
                        data={transformedData}
                        x="date"
                        y="totalDollarValue"
                        style={{
                            data: { stroke: "#4287f5" }
                        }}
                    />
                    <VictoryScatter
                        data={transformedData}
                        x="date"
                        y="totalDollarValue"
                        size={scatterSize} // Utiliser la taille définie par l'état
                        style={{ data: { fill: (datum) => determineColor(datum) } }}
                        labels={({ datum }) => {
                            let transactionsInfo = renderTransactions(datum.infosTransactions);
                            return `
                                Date: ${datum.date.toLocaleDateString()}
                                Montant investi: $${datum.totalInvested}
                                Valeur des actifs: $${datum.totalDollarValue}
                                ---
                                Transactions:
                                ${transactionsInfo}
                            `;
                        }}
                        labelComponent={
                            <VictoryTooltip 
                                style={{ fontFamily: "Arial", fontSize: tooltipFontSize }}
                                flyoutStyle={{ padding: 0 }} // Ajustez cette valeur selon vos besoins
                            />
                        }
                    />


                    <VictoryLegend x={125} y={10}
                        orientation="horizontal"
                        gutter={20}
                        style={{ border: { stroke: "black" }, labels: {fontSize: fontSize + 2 } }}
                        data={[
                            { name: "Montant investi", symbol: { fill: "#f54242" }},
                            { name: "Valeur des actifs", symbol: { fill: "#4287f5" }}
                        ]}
                    />
                </VictoryChart>
            )}
        </div>
    );
}

export default PortfolioGraph;
