import React, {  useState } from "react";
import { FaChevronDown, FaChevronUp, FaSearch } from "react-icons/fa";

import { postRequest } from "../../../Contexts/Services"; // Importez la fonction postRequest

import { useNotification } from "../../../Contexts/NotificationContext"; // Importez le hook useNotification

import { useSpinner } from "../../../Contexts/SpinnerContext";




/**
 * Composant pour encoder des cryptomonnaies.
 * @function CryptoEncodage
 * @param {Object} props - Les propriétés du composant.
 * @param {Function} props.reloadTransactions - Fonction pour recharger les transactions.
 * @param {Array} props.cryptos - Liste des cryptomonnaies.
 * @param {Function} props.loadListCryptos - Fonction pour charger la liste des cryptomonnaies.
 * @returns {JSX.Element} Composant CryptoEncodage.
 */
function CryptoEncodage({ reloadTransactions, cryptos, loadListCryptos }) {
	const [accordionOpen, setAccordionOpen] = useState(false);
	const [foundTokens, setFoundTokens] = useState([]);

	const { showNotification } = useNotification(); // Utilisez le hook pour obtenir la fonction showNotification
	const { showSpinner, hideSpinner } = useSpinner();

   

	/**
	 * Gère la recherche de cryptomonnaies.
	 * @async
	 * @function handleSearchClick
	 * @returns {Promise<void>}
	 */
	const handleSearchClick = async () => {
		try {
			//const searchTerm = /* obtenir la valeur du champ de recherche ici, par exemple: */ document.getElementById("searchInputId").value;
			  const searchTerm = document.getElementById('crypto-name-add').value.trim();

			// Vérifiez si searchTerm est vide avant d'envoyer la requête
			if (!searchTerm.trim()) {
				showNotification("Veuillez entrer un terme de recherche.", "error", "", 0); 
				return;
			}
			
			showSpinner();
			const response = await postRequest('api.php', { 
				uuid: localStorage.getItem('loggedIn'),
				action: 'find_crypto_by_name',
				searchTerm: searchTerm
			});
	
			if (response.success) {
				setFoundTokens(response.message);
				hideSpinner();
			} else {
				showNotification(response.message , "error", "", 0); 
				hideSpinner();
			}
		} catch (error) {
			showNotification(error.message || "Une erreur est survenue lors de la recherche des tokens.", "error", "", 0); 
			hideSpinner();
		}
	};

	/**
	 * Gère l'ajout d'une nouvelle cryptomonnaie.
	 * @async
	 * @function handleAddCrypto
	 * @returns {Promise<void>}
	 */
	const handleAddCrypto = async () => {
		 // 1. Récupérez les valeurs des champs d'entrée
		const cryptoId = document.getElementById('crypto-id-add').value.trim();
		const cryptoName = document.getElementById('crypto-name-add').value.trim();
	
		// Vérifiez si les champs ne sont pas vides
		if (!cryptoId || !cryptoName) {
			showNotification('Veuillez remplir tous les champs.' , "error", "", 0); 
			return;
		}
	
		showSpinner();
		const uuid = localStorage.getItem('loggedIn');
	
		try {
			const response = await postRequest('api.php', { 
				uuid: uuid,
				action: 'add_crypto',
				cryptoId: cryptoId.toLowerCase(),
				cryptoName:cryptoName.toUpperCase()
			});
	
			if (response.success) {
				// Rechargez la liste des cryptos
				await loadListCryptos();
				showNotification("Crypto " + cryptoName.toUpperCase() + " ajoutée avec succès!", "success", "", 0);
				// Réinitialiser les champs du formulaire
				document.getElementById('crypto-id-add').value = '';
				document.getElementById('crypto-name-add').value = '';
				hideSpinner();
				
			} else {
				showNotification(response.message || 'Une erreur est survenue.' , "error", "", 0); 

				hideSpinner();
			}
		  
	
			
		} catch (error) {
		   showNotification(error.message || "Une erreur est survenue." , "error", "", 0); 
			
		} finally {
		   hideSpinner();
		}
	};

 
	/**
	 * Met en majuscule la première lettre d'une chaîne de caractères.
	 * @function capitalizeFirstLetter
	 * @param {string} string - La chaîne de caractères à modifier.
	 * @returns {string} La chaîne de caractères modifiée.
	 */
	function capitalizeFirstLetter(string) {
		return string.charAt(0).toUpperCase() + string.slice(1);
	}

	/**
	 * Ajoute une transaction en appelant l'API avec les informations saisies par l'utilisateur.
	 * @async
	 * @function handleAddTranscation
	 * @returns {Promise<void>}
	 */
	const handleAddTranscation = async () => {
		// Récupérer les valeurs des champs
		const selectedCrypto = document.getElementById('crypto-list');
		const quantity = document.getElementById('crypto-quantity').value;
		const amount = document.getElementById('amount').value;
		
		let location = document.getElementById('crypto-location-add').value;

		const selectedCryptoId = selectedCrypto.value; 
		const selectedCryptoName = selectedCrypto.options[selectedCrypto.selectedIndex].textContent; 
		const transactionType = document.getElementById('transaction-type').value;
		const transactionDate = document.getElementById('transaction-date').value;

		// Validation
		let isComplete = true;
		let message_erreur = "Erreur: \n";

		if (isNaN(quantity) || quantity <= 0) {
			message_erreur += "- Quantité Crypto invalide. \n";
			isComplete = false;
		}
		
		if (isNaN(amount) || amount <= 0) {
			message_erreur += "- Montant USD invalide. \n";
			isComplete = false;
		}

		if (!location) {
			message_erreur += "- Veuillez entrer une location. \n";
			isComplete = false;
		}

		if (!transactionDate) {
			message_erreur += "- Veuillez sélectionner une date. \n";
			isComplete = false;
		}
	   

		if (isComplete === false) {
			
			showNotification(message_erreur, "error", "", 0);
			return;
		}

		showSpinner();

		const uuid = localStorage.getItem('loggedIn');
		const purchasePrice = amount / quantity;
		location = capitalizeFirstLetter(location);
		try {
            const response = await postRequest('api.php', { 
                action: 'add_transactions',
                uuid,
                token: selectedCryptoName,
                tokenId: selectedCryptoId,
                date: transactionDate,
                invest: amount,
                supply: quantity,
                location: location,
                purchasePrice,
                transactionType,
                type: 'add_transactions'
            });
    
            if (response.success) {
               // console.log(response.message);
                showNotification("Transaction ajoutée avec succès!", "success", "", 0);
                /* LOADTRANSACTION */
				await reloadTransactions();
                // Réinitialiser les champs du formulaire
                
                hideSpinner();
            } else {
                showNotification(response.message || 'Une erreur est survenue.' , "error", "", 0); 

                hideSpinner();
            }
          
    
            
        } catch (error) {
           showNotification(error.message || "Une erreur est survenue." , "error", "", 0); 
            
        } finally {
           hideSpinner();
        }
        
	};


	return (
		<div className="container_encodage">
			<div className="accordion-controls">
				<span className="accordion-title">Encodage</span>
				{accordionOpen ? (
					<FaChevronUp
						className="toggle-accordion"
						onClick={() => setAccordionOpen(!accordionOpen)}
					/>
				) : (
					<FaChevronDown
						className="toggle-accordion"
						onClick={() => setAccordionOpen(!accordionOpen)}
					/>
				)}
			</div>

			{accordionOpen && (
				<div id="crypto-section-general" aria-label="Encodage">
					<div
						id="crypto-management-section"
						aria-label="Section de gestion des cryptos"
					>
						<h2 className="titre_encodage_section">Ajout cryptos</h2>
						<div className="search-container">
							<label htmlFor="crypto-name-add" className="label_centre">
								Nom de la crypto:
							</label>
							<div className="search-wrapper">
								<input
									type="text"
									id="crypto-name-add"
									placeholder="Nom de la crypto"
									aria-label="Nom de la crypto"
								/>
								<FaSearch className="search-icon" onClick={handleSearchClick} />
								<div className="search-results">
									{foundTokens.map((token) => (
										<div
											key={token.data.id}
											className="search-result-item"
											onClick={() => {
												document.getElementById("crypto-id-add").value =
													token.data.id;
												document.getElementById("crypto-name-add").value =
													token.data.name;
												setFoundTokens([]); // Vider la liste des résultats
											}}
										>
											<img
												src={token.data.image}
												alt={token.data.name + " icon"}
												className="crypto-icon"
											/>
											<span className="crypto-name">{token.data.name}</span>
											<span className="crypto-id">({token.data.id})</span>
											<span className="crypto-symbol">{token.data.symbol}</span>
											<span className="crypto-price">
												$
												{token.data.current_price
													? token.data.current_price.toFixed(2)
													: "N/A"}
											</span>
										</div>
									))}
								</div>
							</div>
						</div>

						<div className="search-container">
							<label htmlFor="crypto-id-add" className="label_centre">
								Id Crypto:
							</label>
							<input
								type="text"
								id="crypto-id-add"
								placeholder="ID de la crypto"
								aria-label="ID de la crypto"
							/>
						</div>
						<button
							id="add-crypto"
							className="bp_encodage"
							aria-label="Ajouter une crypto"
							onClick={handleAddCrypto}
						>
							Ajouter
						</button>
					</div>

					<div
						id="transaction-section"
						aria-label="Section d'encodage des transactions"
					>
						<h2 className="titre_encodage_section">Ajout Transaction</h2>
                        <div id="crypto-name-wrapper">
                            <label id="crypto-name-label" htmlFor="crypto-list">
								Cryptos:{" "}
							</label>
                            <select id="crypto-list" aria-label="Liste des cryptomonnaies">
                                {cryptos.map((crypto) => (
                                    <option
                                        key={crypto.crypto_name + "_" + crypto.crypto_id}
                                        value={crypto.crypto_id}
                                    >
                                        {crypto.crypto_name}
                                    </option>
                                ))}
                            </select>
                        </div>
						<div id="crypto-quantity-wrapper">
							<label id="crypto-quantity-label" htmlFor="crypto-quantity">
								Quantité de Crypto:{" "}
							</label>
							<input
								type="number"
								id="crypto-quantity"
								placeholder="Quantité"
								aria-label="Quantité de cryptomonnaie"
							/>
						</div>

						<div id="crypto-amount-wrapper">
							<label id="crypto-amount-label" htmlFor="amount">
								Valeur en USD:{" "}
							</label>
							<input
								type="number"
								id="amount"
								placeholder="Investissement en $"
								aria-label="Montant de l'investissement en dollars"
							/>
						</div>

						<div id="crypto-transaction-type-wrapper">
							<label htmlFor="transaction-type">Type de transaction:</label>
							<select id="transaction-type" aria-label="Type de transaction">
								<option value="achat">Achat</option>
								<option value="vente">Vente</option>
							</select>
						</div>

						<div id="crypto-location-wrapper">
							<label htmlFor="crypto-location-add" className="label_centre">
								Location:
							</label>
							<input
								type="text"
								id="crypto-location-add"
								placeholder="Location"
								aria-label="Location"
							/>
						</div>

						<div id="crypto-transaction-date-wrapper">
							<label htmlFor="transaction-date" className="label_centre">
								Date de Transaction:
							</label>
							<input
								type="date"
								id="transaction-date"
								aria-label="Date de la transaction"
							/>
						</div>
						<button
							id="submit-transaction"
							aria-label="Soumettre la transaction"
							className="bp_encodage"
							onClick={handleAddTranscation}
						>
							Soumettre
						</button>
					</div>
				</div>
			)}
		</div>
	);
}

export default CryptoEncodage;
