

// Import de React
import React from 'react';
import { useState } from "react";
// Import de Fa
import { FaEnvelope, FaLock, FaCode } from 'react-icons/fa';

import { postRequest } from "../../../Contexts/Services"; // Importez la fonction postRequest

import { useNotification } from "../../../Contexts/NotificationContext"; // Importez le hook useNotification

import { useSpinner } from "../../../Contexts/SpinnerContext";

/**
 * Composant pour la réinitialisation de mot de passe
 * @returns {JSX.Element} élément JSX contenant les formulaires pour envoyer le code de réinitialisation et réinitialiser le mot de passe
 */
function ResetPasswordForm() {

    // Déclaration des states
    const { showNotification } = useNotification(); // Utilisez le hook pour obtenir la fonction showNotification
    const { showSpinner, hideSpinner } = useSpinner();

    const [email, setEmail] = useState('');
    const [reset_code, setCode] = useState('');
    const [new_password, setPassword] = useState('');

    
    /**
     * Fonction pour gérer l'envoi du code de réinitialisation par mail
     * @param {Event} e - L'événement de soumission du formulaire
     */
    const handleSendCode = async (e) => {
        e.preventDefault();
        showSpinner();
        try {
            await sendCodeByMail(email);
        } catch (err) {
            showNotification(err.message, "error", "", 0); // Utilisez showNotification pour afficher l'erreur
            /* cacher spinner */
            hideSpinner();
        }
    }

    /**
     * Fonction pour envoyer le code de réinitialisation par mail
     * @param {string} email - L'adresse email de l'utilisateur
     * @returns {Promise<void>} Une promesse qui résout lorsque le code est envoyé avec succès, ou qui rejette avec une erreur si une erreur se produit
     */
    async function sendCodeByMail(email) {
        let isComplete = true;
        let message_erreur = "Erreur: \n";

        if (!email) {
            message_erreur += "- Email est requis. \n";
            isComplete = false;
        }

        if (isComplete === false) {
            
            showNotification(message_erreur, "error", "", 0);
            /* cacher spinner */
            hideSpinner();
            return;
        }

        try {
            email = email.trim();
            const data = await postRequest("api.php", {
                email,
                action: "send_code_reset_password",
            });
            if (data.success) {
                showNotification(data.message, "success", "", 0);
            } else {    
                showNotification(data.message, "error", "", 0);
            } 
        // Code pour envoyer le code de réinitialisation par mail
        }
        catch (err) {
            showNotification(err.message, "error", "", 0);
        }
        finally {
            /* cacher spinner */
            hideSpinner();
        }
    }


    /**
     * Fonction pour gérer la réinitialisation du mot de passe de l'utilisateur
     * @param {Event} e - L'événement de soumission du formulaire
     */
    const handleResetPassword = async (e) => {
        e.preventDefault();
        showSpinner();
        try {
            await resetPassword(email, reset_code, new_password);
        } catch (err) {
            showNotification(err.message, "error", "", 0); // Utilisez showNotification pour afficher l'erreur
            /* cacher spinner */
            hideSpinner();
        }
    }

    /**
     * Fonction pour réinitialiser le mot de passe de l'utilisateur
     * @param {string} email - L'adresse email de l'utilisateur
     * @param {string} reset_code - Le code de réinitialisation envoyé par mail
     * @param {string} new_password - Le nouveau mot de passe de l'utilisateur
     * @returns {Promise<void>} Une promesse qui résout lorsque le mot de passe est réinitialisé avec succès, ou qui rejette avec une erreur si une erreur se produit
     */
    async function resetPassword(email, reset_code, new_password) {

        let isComplete = true;
        let message_erreur = "Erreur: \n";

        if (!email) {
            message_erreur += "- Email est requis. \n";
            isComplete = false;
        }

        if (!reset_code) {
            message_erreur += "- Code est requis. \n";
            isComplete = false;
        }

        if (!new_password) {
            message_erreur += "- Nouveau mot de passe est requis. \n";
            isComplete = false;
        }


        if (isComplete === false) {
            
            showNotification(message_erreur, "error", "", 0);
            /* cacher spinner */
            hideSpinner();
            return;
        }

        try {
            email = email.trim();
            reset_code = reset_code.trim();
            new_password = new_password.trim();


            const data = await postRequest("api.php", {
                email,
                reset_code,
                new_password,
                action: "reset_password",
            });
            if (data.success) {
                showNotification(data.message, "success", "/", 3);
            } else {    
                showNotification(data.message, "error", "", 0);
            } 
        // Code pour envoyer le code de réinitialisation par mail
        }
        catch (err) {
            showNotification(err.message, "error", "", 0);
        }
        finally {
            /* cacher spinner */
            hideSpinner();
        }
        
    }


    return (
        <div className="container centered-content">
            <form className='bordure' onSubmit={handleSendCode}>
                <div className="form-group">
                    <label htmlFor="email">Email</label>
                    <FaEnvelope  className='margin_before margin_after' />
                    <input 
                        type="email" 
                        placeholder="Email" 
                        value={email} 
                        onChange={(e) => setEmail(e.target.value)}
                        className='inputLogin'
                    />
                </div>
                <button className='bp_encodage' type="submit">Envoyer le code</button>
            </form>

            <form className='bordure'  onSubmit={handleResetPassword}>
                <div className="form-group">
                    <label htmlFor="reset_code">Code de Réinitialisation</label>
                    <FaCode  className='margin_before margin_after' />
                    <input 
                        type="text" 
                        placeholder="Code de réinitialisation" 
                        value={reset_code} 
                        onChange={(e) => setCode(e.target.value)}
                        className='inputLogin'
                    />
                </div>
                <div className="form-group">
                    <label  htmlFor="new_password">Nouveau mot de passe</label>
                    <FaLock className='margin_before margin_after' />
                    <input 
                        type="password" 
                        placeholder="Nouveau mot de passe" 
                        value={new_password} 
                        onChange={(e) => setPassword(e.target.value)}
                        className='inputLogin'
                    />
                </div>
                <button className='bp_encodage' type="submit">Réinitialiser le mot de passe</button>
            </form>
           

        </div>
    );

}

export default ResetPasswordForm;