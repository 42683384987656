import { useNotification } from "../../../Contexts/NotificationContext";

 /**
   * Ce composant récupère les informations de notification à partir du hook useNotification et les utilise pour afficher une notification modale.
   * @typedef {Object} Notification
   * @property {string} message - Le message de la notification.
   * @property {string} messageType - Le type de la notification.
   * @property {boolean} isVisible - Indique si la notification est visible ou non.
   * @property {string} redirectUrl - L'URL de redirection de la notification.
   * @property {number} timerNotification - Le temps d'affichage de la notification.
   * @property {function} hideNotification - La fonction pour masquer la notification.
   */
function ModalNotification() {
 
  /**
   * Ce composant récupère les informations de notification à partir du hook useNotification
   * et les utilise pour afficher une notification modale.
   * @typedef {Object} Notification
   * @property {string} message - Le message de la notification.
   * @property {string} messageType - Le type de la notification.
   * @property {boolean} isVisible - Indique si la notification est visible ou non.
   * @property {string} redirectUrl - L'URL de redirection de la notification.
   * @property {number} timerNotification - Le temps d'affichage de la notification.
   * @property {function} hideNotification - La fonction pour cacher la notification.
   */
  const {
    message,
    messageType,
    isVisible,
    redirectUrl,
    timerNotification,
    hideNotification,
  } = useNotification();

  /**
   * Ferme la notification et redirige vers l'URL spécifiée si elle est fournie, sinon cache la notification.
   * @function
   * @name handleClose
   * @returns {void}
   */
  const handleClose = () => {
    if (redirectUrl && redirectUrl !== "") {
      window.location.href = redirectUrl;
    } else {
      hideNotification();
    }
  };

  // Fermez le modal si timerNotification > 0
  if (timerNotification > 0) {
    setTimeout(() => {
      handleClose();
    }, timerNotification * 1000);
  }

  const messageLines = message.split('\n');
  
  return (
    <>
      {isVisible && (
        <div className={`modal-notification ${isVisible ? "visible" : ""}`}>
          <div className={`modal-notification__content ${messageType}`}>
          {messageLines.map((line, index) => (
              <p key={index}>{line}</p>
            ))}
            <button className="bp_profile" onClick={handleClose}>Fermer</button>
          </div>
        </div>
      )}
    </>
  );
}

export default ModalNotification;
