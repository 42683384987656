import React from 'react';
import { FaTimes, FaSignOutAlt, FaRedo, FaKey, FaSave, FaUpload, FaTrashAlt, FaBook } from "react-icons/fa";
//import { useState } from "react";
import { postRequest, postRequestFile } from "../../../Contexts/Services"; // Importez la fonction postRequest

import { useNotification } from "../../../Contexts/NotificationContext"; // Importez le hook useNotification

import { useSpinner } from "../../../Contexts/SpinnerContext";



import { Link } from 'react-router-dom';

function Sidebar(props) {
    const { showNotification } = useNotification(); // Utilisez le hook pour obtenir la fonction showNotification
	const { showSpinner, hideSpinner } = useSpinner();

    
    const fileInputRef = React.useRef(null);  // Créez une référence pour l'élément d'entrée du fichier

    const logout = () => {
        localStorage.removeItem('loggedIn');
        localStorage.removeItem('email');
        localStorage.removeItem('free');
        localStorage.removeItem('matic_wallet');
        window.location.href = "/";
    }

    const refreshData = () => {
        props.refreshData(); 
    }

    const changePassword = async () => {

        const uuid = localStorage.getItem('loggedIn');
        if (!uuid) {
            showNotification("'Erreur: UUID non trouvé.", "error", "", 0); 
            return;
        }
        const newPassword = prompt('Veuillez entrer votre nouveau mot de passe:');
        if (newPassword && newPassword.trim() !== '') {
            showSpinner();
            try {

                const response = await postRequest('api.php', { 
                    uuid: localStorage.getItem('loggedIn'),
                    action: 'change_password',
                    newPassword: newPassword.trim()
                });
        
                if (response.success) {
                    localStorage.removeItem('loggedIn');
                    localStorage.removeItem('email');
                    showNotification(response.message, "success", "/", 3);
                    hideSpinner();
                } else {
                    showNotification(response.message , "error", "", 0); 
                    hideSpinner();
                }
                
            } catch (error) {
                showNotification(error.message || "Erreur lors du changement du mot de passe.", "error", "", 0); 
                hideSpinner();
            }
        }
       
    }

    const backupData = async () => {
        const uuid = localStorage.getItem('loggedIn');
        if (!uuid) {
            showNotification("'Erreur: UUID non trouvé.", "error", "", 0); 
            return;
        }

        showSpinner();
        try {

            const response = await postRequest('api.php', { 
                uuid: localStorage.getItem('loggedIn'),
                action: 'backup_data'
            });
    
            if (response.success) {
                // Créer un élément <a> pour déclencher le téléchargement
                let downloadLink = document.createElement('a');
                downloadLink.href = response.message;
                downloadLink.download = response.message.split('/').pop(); // Assurez-vous d'obtenir le nom du fichier sans chemin
                console.log(downloadLink)
                document.body.appendChild(downloadLink);
                downloadLink.click();
                document.body.removeChild(downloadLink);
                showNotification("Exportation réalisée avec succès! il a été télécharger dans votre dossier téléchargements", "success", "", 0);
                hideSpinner();
            } else {
                showNotification(response.message , "error", "", 0); 
                hideSpinner();
            }
            
        } catch (error) {
            showNotification(error.message || "Erreur lors du backup.", "error", "", 0); 
            hideSpinner();
        }
   
    }
    

    const restoreData = () => {
        // Logique pour restaurer les données   import_react.php
        fileInputRef.current.click();
    }


    const handleFileChange = async (event) => {
      
        const file = event.target.files[0];
        if (!file) return;

        const uuid = localStorage.getItem('loggedIn');
        if (!uuid) {
            showNotification("'Erreur: UUID non trouvé.", "error", "", 0); 
            return;
        }

        showSpinner();
        try {

            const response = await postRequestFile('api.php', { 
                uuid: localStorage.getItem('loggedIn'),
                action: 'restore_data',
                importFile: file
            });
    
            if (response.success) {
                
                showNotification(response.message, "success", "", 0);

                hideSpinner();
                props.refreshCrypto();
                props.refreshData();
            } else {
                showNotification(response.message , "error", "", 0); 
                hideSpinner();
            }
            
        } catch (error) {
            showNotification(error.message || "Erreur lors du changement du mot de passe.", "error", "", 0); 
            hideSpinner();
        }
/*

        try {
            const response = await postFileRequest('import_react.php', {
                uuid: uuid,
                importFile: file
            });

            if (response.status === 'success') {
                setMessageSidebar(response.message);
                setMessageTypeSidebar('success');
                setShowModalSidebar(true);
                props.refreshData();
            } else {
                setMessageSidebar(response.message || 'Une erreur est survenue');
                setMessageTypeSidebar('error');
                setShowModalSidebar(true);
            }
        } catch (error) {
            setMessageSidebar("Une erreur est survenue lors de l'importation des données.");
            setMessageTypeSidebar('error');
            setShowModalSidebar(true);
        }*/
    };

    const deleteWallet = async () => {
        const uuid = localStorage.getItem('loggedIn');
        if (!uuid) {
            showNotification("'Erreur: UUID non trouvé.", "error", "", 0); 
            return;
        }

        const confirmation = window.confirm('Êtes-vous sûr de vouloir supprimer votre portefeuille? Cette action est irréversible.');
        if (!confirmation) return;
        showSpinner();
        try {


            const response = await postRequest('api.php', { 
                uuid: localStorage.getItem('loggedIn'),
                action: 'delete_wallet'
            });
    
            if (response.success) {
              
                showNotification(response.message, "success", "/", 3);
                hideSpinner();
            } else {
                showNotification(response.message , "error", "", 0); 
                hideSpinner();
            }
            
        } catch (error) {
            showNotification(error.message || "Erreur lors du changement du mot de passe.", "error", "", 0); 
            hideSpinner();
        }
      
    }
    
    return (
        <>
        <div className="sidebar" style={{ display: props.isSidebarVisible ? 'block' : 'none' }}>
            <div className="sidebar-close-container">
                <FaTimes className="close-icon" onClick={props.toggleSidebar} size={24} />
            </div>
            <div id="user-info">
                <div id="user-email-wrapper">
                    <label id="user-mail-label" htmlFor="user-mail-display" className='titre_sidebar'>Email: </label><br />
                    <span id="user-mail-display" className='info_sidebar'>{props.userEmail}</span>
                </div>
                <div id="user-uuid-wrapper">
                    <label id="user-uuid-label" htmlFor="user-uuid-display" className='titre_sidebar'>UUID: </label><br />
                    <span id="user-uuid-display"  className='info_sidebar'>{props.userUuid}</span>
                </div>

                <div id="user-abo-wrapper">
                    <label id="user-abo-label" htmlFor="user-abo-display" className='titre_sidebar'>Abonnement: </label><br />
                    <span id="user-abo-display"  className='info_sidebar'>{props.userAbo} <br /> 
                        {localStorage.getItem("free") === "true" && (
                            <Link className='lien_abo' to="/upgrade_abo">version complète</Link>
                        )}
                        
                    </span>
                </div>

                {localStorage.getItem("matic_wallet") && localStorage.getItem("matic_wallet") !== "NULL" && localStorage.getItem("matic_wallet") !== undefined && (
                    <div id="user-uuid-wrapper">
                        <label id="user-uuid-label" htmlFor="user-uuid-display" className='titre_sidebar'>Matic Wallet: </label><br />
                        <span id="user-uuid-display"  className='matic_adresse_info'>{localStorage.getItem("matic_wallet")}</span>
                    </div>
                )}
                

                <button className="bp_profile bp_profile_orange" onClick={logout}>
                    <FaSignOutAlt /> Se déconnecter
                </button>
                <button className="bp_profile bp_profile_vert" onClick={refreshData}>
                    <FaRedo /> Rafraîchir les Datas
                </button>
                <button className="bp_profile" onClick={changePassword}>
                    <FaKey /> Changer mot de passe
                </button>
                <hr />
                <button className="bp_profile bp_profile_bleu" onClick={backupData}>
                    <FaSave /> Sauvegarde
                </button>
                <button className="bp_profile bp_profile_bleu" onClick={restoreData}>
                    <FaUpload /> Restauration
                </button>
                <button className="bp_profile bp_profile_rouge" onClick={deleteWallet}>
                    <FaTrashAlt /> Supprimer Portefeuille
                </button>
                <a href="/docs" target="_self" className='lien_doc' rel="noopener noreferrer">
                    <FaBook /> Documentation
                </a>
                
            </div>
         

            <input
                type="file"
                style={{ display: 'none' }}
                ref={fileInputRef}
                onChange={handleFileChange}
                accept=".sql"
            />

            

        </div>
        </>
    );
}

export default Sidebar;
