import { useState } from "react";
import { postRequest } from "../../../Contexts/Services"; // Importez la fonction postRequest

import { useNotification } from "../../../Contexts/NotificationContext"; // Importez le hook useNotification

import { useSpinner } from "../../../Contexts/SpinnerContext";

import { FaEnvelope, FaLock } from "react-icons/fa";


/**
 * Composant de formulaire d'inscription
 * @returns {JSX.Element} élément JSX représentant le formulaire d'inscription
 */
function RegisterForm() {
    const { showNotification } = useNotification(); // Utilisez le hook pour obtenir la fonction showNotification
    const { showSpinner, hideSpinner } = useSpinner();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('')
   

    /**
     * Fonction appelée lors de la soumission du formulaire
     * @param {Event} e événement de soumission du formulaire
     */
    const handleSubmit = async (e) => {
        e.preventDefault();
        /* afficher spinner */
        showSpinner();

        try {
            await registerUser(email, password, confirmPassword);
        } catch (err) {
            showNotification(err.message, "error", "", 0); // Utilisez showNotification pour afficher l'erreur
            /* cacher spinner */
            hideSpinner();
        }

    }


    /**
     * Fonction pour enregistrer un utilisateur
     * @param {string} email adresse email de l'utilisateur
     * @param {string} password mot de passe de l'utilisateur
     * @param {string} confirmPassword confirmation du mot de passe de l'utilisateur
     */
    async function registerUser(email, password, confirmPassword) {

        let isComplete = true;
        let message_erreur = "Erreur: \n";

        if (!email) {
            message_erreur += "- Email est requis. \n";
            isComplete = false;
        }

        if (!password) {
            message_erreur += "- Mot de passe est requis. \n";
            isComplete = false;
        }

        if (!confirmPassword) {
            message_erreur += "- la confirmation du mot de passe est requis. \n";
            isComplete = false;
        }


        if (password.trim() !== confirmPassword.trim()) {
            message_erreur += "- Les mots de passe ne correspondent pas. \n";
            isComplete = false;
        }

        if (isComplete === false) {
            
            showNotification(message_erreur, "error", "", 0);
            /* cacher spinner */
            hideSpinner();
            return;
        }
        
        try {

            email = email.trim();
            password = password.trim();

            const data = await postRequest("api.php", {
                email,
                password,
                action: "register",
            });
         
            if (data.success) {
                localStorage.setItem("loggedIn", data.message.uuid);
                localStorage.setItem("email", data.message.email);
                localStorage.setItem("email", true);
                showNotification("Inscription réussie. Vous pouvez maintenant vous connecter.", "success", "/", 3);
            } else {    
                showNotification(data.message, "error", "", 0);
            }   
        }
        catch (err) {
            showNotification(err.message, "error", "", 0);
        }
        finally {
            /* cacher spinner */
            hideSpinner();
        }
    }




    return (
        <div className="container centered-content">
            <form onSubmit={handleSubmit}>
                <div className="form-group">
                    <label htmlFor="email">Email</label>
                    <FaEnvelope className='margin_before margin_after' />
                    <input 
                        type="email" 
                        placeholder="Email" 
                        value={email} 
                        onChange={(e) => setEmail(e.target.value)}
                        className='inputLogin'
                    />
                </div>
                
                <div className="form-group">
                    <label htmlFor="password">Mot de passe</label>
                    <FaLock className='margin_before margin_after' />
                    <input 
                        type="password" 
                        placeholder="Mot de passe" 
                        value={password} 
                        onChange={(e) => setPassword(e.target.value)}
                        className='inputLogin'
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="confirmPassword">Confirmation du mot de passe</label>
                    <FaLock className='margin_before margin_after' />
                    <input 
                        type="password" 
                        placeholder="Confirmez le mot de passe" 
                        value={confirmPassword} 
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        className='inputLogin'
                    />
                </div>
                <button className='bp_encodage' type="submit">S'inscrire</button>

                
            </form>
            
        </div>
    );
}

export default RegisterForm;