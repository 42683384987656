import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { AuthProvider, useAuth } from './Contexts/AuthContext';
import { NotificationProvider } from './Contexts/NotificationContext';  
import { SpinnerProvider, useSpinner } from './Contexts/SpinnerContext';
import './Design/themes.css';

import LoginForm from './Components/ui/Login_Inscription/LoginForm';
import RegisterForm from './Components/ui/Login_Inscription/RegisterForm';
import ResetPasswordForm from './Components/ui/Login_Inscription/ResetPasswordForm';
import HomeComponent from './Components/ui/HomeComponent';
import Documentation from './Components/Documentation';
import Header from './Components/ui/Header';
import UpgradeToFullVersion from './Components/ui/Utils_ui/UpgradeToFullVersion';

import ModalNotification from './Components/ui/Utils_ui/ModalNotification';
import Spinner from './Components/ui/Utils_ui/Spinner';


/**
 *
 * @description Affiche les routes de l'application
 * @export
 * @class AppRoutes
 * @extends {Component}
 * @example
 *  
 * @see https://reactrouter.com/web/guides/quick-start
 * @see https://reactrouter.com/web/example/auth-workflow
 *
 * @return {*} 
 */
function AppRoutes() {
  const { isAuthenticated } = useAuth();

  return (
    <Routes>
      <Route path="/login" element={isAuthenticated ? <Navigate to="/" /> : <LoginForm />} />
      <Route path="/register" element={isAuthenticated ? <Navigate to="/" /> : <RegisterForm />} />
      <Route path="/reset-password" element={isAuthenticated ? <Navigate to="/" /> : <ResetPasswordForm />} />
      <Route path="/" element={isAuthenticated ? <HomeComponent /> : <Navigate to="/login" />} />
      <Route path="/docs" element={isAuthenticated ? <Documentation /> : <Navigate to="/login" />} />
      <Route path="/upgrade_abo" element={isAuthenticated ? <UpgradeToFullVersion /> : <Navigate to="/login" />} />
    </Routes>
  );
}


/**
 *  
 * @description Affiche le wrapper de l'application
 * @export
 * @class AppWrapper
 * @extends {Component}
 * @example
 * <AppWrapper />
 * @see https://reactrouter.com/web/guides/quick-start
 * @see https://reactrouter.com/web/example/auth-workflow
 * 
 * @return {*} 
 */
function AppWrapper() {
  return (
    <AuthProvider>
      <NotificationProvider>
        <SpinnerProvider>
          <App />
        </SpinnerProvider>
      </NotificationProvider>
    </AuthProvider>
  );
}


/**
 *  
 * @description Affiche l'application
 * @export
 * @class App
 * @extends {Component}
 * @example
 * <App />
 * @see https://reactrouter.com/web/guides/quick-start
 * @see https://reactrouter.com/web/example/auth-workflow
 * 
 * @return {*} 
 */
function App() {
  const { isVisible } = useSpinner();
  return (
    <>
      <Header />
      <Router>
        <AppRoutes />
      </Router>
      <ModalNotification />
      {isVisible && <div className="spinner-background"></div>}
      {isVisible && <Spinner />}    
    </>
  );
}

export default AppWrapper;
