
import React,{ useEffect, useState} from "react";
import {
    VictoryChart,
    VictoryTheme,
    VictoryLine,
    VictoryAxis,
    VictoryLegend,
    VictoryZoomContainer,
    VictoryTooltip,
    VictoryScatter
} from 'victory';

/**
 * Composant graphique pour afficher le graphique du portefeuille total.
 * @param {Object} props - Les propriétés du composant.
 * @param {Object} props.data - Les données du graphique.
 * @returns {JSX.Element} - Le composant graphique du portefeuille total.
 */
function TokenGraph({ data }) {
    const [transformedData, setTransformedData] = useState([]);
    const [fontSize, setFontSize] = useState(14);
    const [tooltipFontSize, setTooltipFontSize] = useState(12);
    const [scatterSize, setScatterSize] = useState(3); // Taille par défaut des pastilles


    /**
     * Met à jour les tailles des éléments du graphique en fonction de la taille de l'écran.
     */
    useEffect(() => {
        const updateSizes = () => {
            const width = window.innerWidth;

            if (width <= 480) {
                setFontSize(10);
                setTooltipFontSize(8);
                setScatterSize(2); // Réduire la taille des pastilles pour les petits écrans
            } else if (width <= 768) {
                setFontSize(8);
                setTooltipFontSize(10);
                setScatterSize(3);
            } else {
                setFontSize(4);
                setTooltipFontSize(4);
                setScatterSize(2); // Augmenter la taille des pastilles pour les grands écrans
            }
        };

        window.addEventListener("resize", updateSizes);
        updateSizes();

        return () => {
            window.removeEventListener("resize", updateSizes);
        };
    }, []);



    /**
     * Transforme les données du graphique pour les rendre compatibles avec VictoryChart.
     */
    useEffect(() => {
        if (data && data.chartData) {
            const chartData = Object.entries(data.chartData).map(([date, values]) => ({
                date: new Date(date),
                cryptoAmount: values.cryptoAmount,
                dollarValue: values.dollarValue,
                investedAmount: values.investedAmount,
                type: values.type
            }));
            const sortedData = chartData.sort((a, b) => a.date - b.date);
            //console.log(sortedData);
            setTransformedData(sortedData);
        }
    }, [data]);

    /**
     * Détermine la couleur des pastilles en fonction des transactions.
     * @param {Object} datum - Les données du graphique.
     * @returns {string} - La couleur des pastilles.
     */
    const determineColor = (datum) => {
        switch(datum.datum.type) {
            case "achat":
                return "green";
            case "vente":
                return "red";
            case "first":
                return "grey";
            case "last":
                return "grey";
            default:
                return "grey"; // couleur par défaut si aucun critère n'est rempli
        }
    };

    
  

// Calculez les domaines
  const maxCrypto = Math.max(...transformedData.map(d => d.cryptoAmount));
    const minCrypto = Math.min(...transformedData.map(d => d.cryptoAmount));

    const maxDollar = Math.max(
        ...transformedData.map(d => d.dollarValue),
        ...transformedData.map(d => d.investedAmount)
    );
    const minDollar = Math.min(
        ...transformedData.map(d => d.dollarValue),
        ...transformedData.map(d => d.investedAmount)
    );

    //console.log("maxCrypto:" + maxCrypto + " minCrypto:" + minCrypto + " maxDollar:" + maxDollar + " minDollar:" + minDollar);
    // Rendu du composant
    return (
        <div id="total-portfolio-token-container" className='container-graphique-token' aria-label="Conteneur du graphique du token">
            <h2>Graphique du {data.tokenName}</h2>
            {transformedData.length > 0 && (
                <div style={{ position: 'relative' }}>
                    {/* Premier graphique pour dollarValue et investedAmount */}
                    <VictoryChart
                        theme={VictoryTheme.material}
                        height={200}
                        padding={{ top: 20, bottom: 50, right: 30, left: 60 }}
                        domainPadding={20}
                        containerComponent={<VictoryZoomContainer />}
                    >
                        <VictoryAxis
                            tickFormat={(x) => {
                                const date = new Date(x);
                                return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
                            }}
                            style={{ tickLabels: { fontSize } }}
                        />
                        <VictoryAxis
                            dependentAxis
                            tickFormat={(y) => `$${y}`}
                            style={{ tickLabels: { fontSize } }}
                            orientation="left"
                            domain={[minDollar, maxDollar]}
                        />
                        <VictoryLine
                            data={transformedData}
                            x="date"
                            y="dollarValue"
                            style={{ data: { stroke: "#4287f5" } }}
                        />
                        <VictoryLine
                            data={transformedData}
                            x="date"
                            y="investedAmount"
                            style={{ data: { stroke: "#9f47f5" } }}
                        />

                        <VictoryScatter
                            data={transformedData}
                            x="date"
                            y="dollarValue"
                            size={scatterSize} // Utiliser la taille définie par l'état
                            style={{ data: { fill: (datum) => determineColor(datum) } }}
                            labels={({ datum }) => {
                                let symbol;
                                switch(datum.type) {
                                    case 'achat':
                                        symbol = '🟢';
                                        break;
                                    case 'vente':
                                        symbol = '🔴';
                                        break;
                                    case 'first':
                                    case 'last':
                                        symbol = ''; // Pas de symbole pour 'first' et 'last'
                                        break;
                                    default:
                                        symbol = '';
                                }
                                
                                return `${symbol} Jeton: ${data.tokenName}
                                    Date: ${datum.date.toLocaleDateString()}
                                    Montant investi: $${datum.investedAmount}
                                    Valeur des actifs: $${datum.dollarValue}
                                    Nombre de Crypto: ${datum.cryptoAmount}
                                    
                                `;
                            }}
                            labelComponent={
                                <VictoryTooltip 
                                    style={{ fontFamily: "Arial", fontSize: tooltipFontSize }}
                                    flyoutStyle={{ padding: 0 }} // Ajustez cette valeur selon vos besoins
                                />
                            }
                        />

                        {/* Légende et autres éléments */}
                        <VictoryLegend x={125} y={10}
                            orientation="horizontal"
                            gutter={20}
                            style={{ border: { stroke: "black" }, labels: { fontSize: fontSize + 2 } }}
                            data={[
                                { name: "Montant investi", symbol: { fill: "#9f47f5" } },
                                { name: "Valeur des actifs", symbol: { fill: "#4287f5" } }
                            ]}
                        />
                    </VictoryChart>

                    {/* Deuxième graphique pour cryptoAmount */}
                    <VictoryChart
                        theme={VictoryTheme.material}
                        height={200}
                        padding={{ top: 20, bottom: 50, right: 30, left: 60 }}
                        domainPadding={20}
                        containerComponent={<VictoryZoomContainer />}
                        style={{ position: 'absolute', top: 0, left: 0 }}
                    >
                        <VictoryAxis
                            tickFormat={(x) => {
                                const date = new Date(x);
                                return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
                            }}
                            style={{ tickLabels: { fontSize, opacity: 0 } }} // Masquer cet axe pour éviter la duplication
                        />
                        <VictoryAxis
                            dependentAxis
                            tickFormat={(y) => `${y}`}
                            style={{ tickLabels: { fontSize, fill: "#f54242" } }}
                            orientation="right"
                            domain={[minCrypto, maxCrypto]}
                        />
                        <VictoryLine
                            data={transformedData}
                            x="date"
                            y="cryptoAmount"
                            style={{ data: { stroke: "#f54242" } }}
                        />

                        <VictoryScatter
                            data={transformedData}
                            x="date"
                            y="cryptoAmount"
                            size={scatterSize} // Utiliser la taille définie par l'état
                            style={{ data: { fill: (datum) => determineColor(datum) } }}
                            labels={({ datum }) => {
                                let symbol;
                                switch(datum.type) {
                                    case 'achat':
                                        symbol = '🟢';
                                        break;
                                    case 'vente':
                                        symbol = '🔴';
                                        break;
                                    case 'first':
                                    case 'last':
                                        symbol = ''; // Pas de symbole pour 'first' et 'last'
                                        break;
                                    default:
                                        symbol = '';
                                }
                                
                                return `${symbol} Jeton: ${data.tokenName}
                                    Date: ${datum.date.toLocaleDateString()}
                                    Montant investi: $${datum.investedAmount}
                                    Valeur des actifs: $${datum.dollarValue}
                                    Nombre de Crypto: ${datum.cryptoAmount}
                                    
                                `;
                            }}
                            labelComponent={
                                <VictoryTooltip 
                                    style={{ fontFamily: "Arial", fontSize: tooltipFontSize }}
                                    flyoutStyle={{ padding: 0 }} // Ajustez cette valeur selon vos besoins
                                />
                            }
                        />
                        {/* Légende et autres éléments */}
                        <VictoryLegend x={125} y={10}
                            orientation="horizontal"
                            gutter={20}
                            style={{ border: { stroke: "black" }, labels: { fontSize: fontSize + 2 } }}
                            data={[
                                
                                { name: "Nombre de Crypto", symbol: { fill: "#f54242" }}
                            ]}
                    />
                    </VictoryChart>

                    
                </div>
            )}
        </div>
    );
}

export default TokenGraph;
