/**
 * URL du proxy utilisé pour les requêtes.
 * @constant {string}
 */
//const PROXY_URL = "http://localhost:6061/";

/**
 * URL de base pour les requêtes.
 * @constant {string}
 */
const finalURL = "https://wallet-crypto.be/php/";
//const finalURL  = "https://localhost/php/";
/**
 * URL finale pour les requêtes.
 * @constant {string}
 */
//const finalURL = PROXY_URL + BASE_URL;

/**
 * Effectue une requête POST à l'endpoint spécifié avec les données fournies.
 * @param {string} endpoint - L'endpoint de l'API à appeler.
 * @param {Object} data - Les données à envoyer avec la requête.
 * @returns {Promise<Object>} - Une promesse qui résout avec les données renvoyées par l'API.
 */
export async function postRequest(endpoint, data) {
    const URL = finalURL + endpoint;
    const formData = new URLSearchParams();
    for (const key in data) {
        formData.append(key, data[key]);
    }

    try {
        const response = await fetch(URL, {
            method: 'POST',
            body: formData
        });

        if (!response.ok) {
            throw new Error(response.statusText);
        }
        
        const data = await response.json();        
        return data;
    } catch (error) {
        console.log(error);
    }
}


export async function postRequestFile(endpoint, fileData) {
    const URL = finalURL + endpoint;
    
    const formData = new FormData();
    for (const key in fileData) {
        formData.append(key, fileData[key]);
    }

    try {
        const response = await fetch(URL, {
            method: 'POST',
            body: formData, // Pas besoin de définir manuellement l'en-tête Content-Type ici. Le navigateur le fera pour vous.
        });

        if (!response.ok) {
            throw new Error("Erreur serveur: " + response.statusText);
        }

        return await response.json();
    } catch (error) {
        console.log(error);
    }
    
   

  
}