import React, { createContext, useContext, useState } from 'react';

// Créez le contexte
const NotificationContext = createContext();

export function useNotification() {
    return useContext(NotificationContext);
}

/**
 * Fournit un contexte pour afficher des notifications.
 * @function
 * @name NotificationProvider
 * @param {Object} props - Les propriétés React.
 * @param {Object} props.children - Les éléments enfants React.
 * @returns {JSX.Element} Le composant NotificationProvider.
 */
export function NotificationProvider({ children }) {
    const [message, setMessage] = useState('');
    const [messageType, setMessageType] = useState('');
    const [isVisible, setIsVisible] = useState(false);
    const [redirectUrl, setRedirectUrl] = useState('');
    const [timerNotification, setTimerNotification] = useState(0);

    /**
     * Affiche une notification avec un message, un type, une URL et un timer.
     * @function
     * @name showNotification
     * @param {string} msg - Le message à afficher dans la notification.
     * @param {string} type - Le type de la notification (success, error, warning, info).
     * @param {string} [url=''] - L'URL vers laquelle rediriger l'utilisateur lorsqu'il clique sur la notification.
     * @param {number} [timer=0] - Le temps en millisecondes avant que la notification ne disparaisse automatiquement (0 pour ne pas disparaître automatiquement).
     * @returns {void}
     */
    const showNotification = (msg, type, url = '', timer = 0) => {
        setMessage(msg);
        setMessageType(type);
        setIsVisible(true);
        setRedirectUrl(url);
        setTimerNotification(timer);
    };

    /**
     * Masque la notification en réinitialisant les valeurs de l'état.
     * @function
     * @name hideNotification
     * @returns {void}
     */
    const hideNotification = () => {
        setIsVisible(false);
        setMessage('');
        setMessageType('');
        setRedirectUrl('');
        setTimerNotification(0);
    };

    /**
     * Objet de valeur de contexte de notification.
     * @typedef {Object} NotificationContextValue
     * @property {function} showNotification - Fonction pour afficher une notification.
     * @property {function} hideNotification - Fonction pour masquer une notification.
     * @property {string} message - Message de notification.
     * @property {string} messageType - Type de message de notification.
     * @property {boolean} isVisible - Indicateur pour indiquer si la notification est visible.
     * @property {string} redirectUrl - URL vers laquelle rediriger après que la notification est cliquée.
     * @property {number} timerNotification - Minuteur pour que la notification disparaisse.
     */
    const value = {
        showNotification,
        hideNotification,
        message,
        messageType,
        isVisible,
        redirectUrl,
        timerNotification
    };

    return (
        <NotificationContext.Provider value={value}>
            {children}
        </NotificationContext.Provider>
    );
}
